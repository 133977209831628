import React from "react";

const ContactUs = () => {
    return (
        <div className="contactus_wrap">
            <div className="container">
                <div className="row">
                    <h3 className="col-col-lg-12 contactus_title">
                        Contact us
                    </h3>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-lg-6 address_wrap">
                        <p>address</p>
                        <address>
                            (13840) 경기 과천시 과천대로7길 65 과천상상자이타워 1층
                        </address>
                        <p>
                        과천 스타트업센터
                        </p>
                    </div>
                    <div className="col-xs-12 col-lg-6 address_wrap2">
                        <p>tel</p>
                        <address>
                            영업담당자 직접 문의 010-7178-3960
                        </address>
                        <p>email</p>
                        <address>
                            biz@wisenexus.co.kr
                        </address>
                        <span>
                            *언제든 메일을 통해 문의를 남겨주시면 운영시간내에 즉시 답변을 드립니다.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;