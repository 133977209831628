import React, { useEffect, useState, useRef } from "react";
import mobile_btn from '../../../style/image/01_button.png';
import mobile_netar from '../../../style/image/02_netar_main.png';
import company_logo1 from '../../../style/image/company/03_logo_01.png';
import company_logo2 from '../../../style/image/company/04_logo_02.png';
import AOS from 'aos';
import "aos/dist/aos.css";
import { use_channel_tok } from "../../../customHooks/useClickHooks";

const MobileMainPage = () => {

    // aos animation reset and refresh
    useEffect(()=>{
        AOS.init();
        AOS.refresh();
    }, []);

    const { open_tok } = use_channel_tok();

    return(
        <section className="main_wrap">
            <article className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12 text_wrap">
                        <span>몇 번의 클릭만으로<br /> 웹 데이터를 빠르고,<br />정확하게 수집.</span>
                        <div className="intro_text">
                            <pre>
                            데이터 크롤링 빠르게 도와드리겠습니다. <br />
                            데이터 수집부터 대시보드 세팅까지 한 번에!
                            </pre>
                        </div>
                        <div className="estimate_wrap">
                            <img className="img_btn" src={mobile_btn} alt="버튼 이미지" onClick={()=>{ open_tok() }} />
                        </div>
                    </div>
                    <div className="col-lg-12 align-self-end image_wrap">
                        <img src={mobile_netar} alt="네타르 이미지"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="linear"
                        />
                    </div>    
                </div> 
                <div className="company_wrap">
                    <div className="row">
                        <div className="col-lg-12 company_intro">고객사 소개</div>
                        <div className="col logo_wrap">
                            <div className="company_line">
                                <img src={company_logo1} alt="로고 이미지" />
                                <img src={company_logo2} alt="로고 이미지" />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default MobileMainPage;