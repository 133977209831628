import React from "react";
import { FaMapMarkerAlt } from 'react-icons/fa';

const Marker = () => {
    return(
        <>
            <FaMapMarkerAlt />
        </>
    )
}

export default Marker;