import React, { useEffect, useState, useRef } from "react";
import estimate_btn from '../../style/image/버튼.png'; 
import netar_image from '../../style/image/netar1.png';
import background_img from '../../style/image/background.png';
import netar_image2 from '../../style/image/natar2.png';
import cj_logo from '../../style/image/company/1_cj.png';
import kolon_logo from '../../style/image/company/2_kolon.png';
import hyorim_logo from '../../style/image/company/3_hyorim.png';
import llooggoo_logo from '../../style/image/company/4_llooggoo.png';
import cottrell_logo from '../../style/image/company/5_cottrell.png';
import kici_log from '../../style/image/company/6_kici_logo.png';
import AOS from 'aos';
import "aos/dist/aos.css";
import { use_channel_tok } from "../../customHooks/useClickHooks";

const MainPage = () => {

    const [margin_value, set_margin_value] = useState(410);
    const margin_ref = useRef(null)
    var client_circle;

    const { open_tok } = use_channel_tok();

    // aos animation reset and refresh
    useEffect(()=>{
        AOS.init();
        AOS.refresh();
    }, []);
    
    // get scroll value 
    useEffect(()=>{
        window.addEventListener('scroll', handle_scroll);
    }, []);

    // margin_value reset useEffect
    useEffect(()=>{
        if(margin_value < -300){
            set_margin_value(410);
        }
    }, [margin_value])

    const start_carousel = () => {
        client_circle = setInterval(()=>{ set_margin_value(margin_value => margin_value - 1) }, 100)
        window.removeEventListener('scroll', handle_scroll);
    }

    // auto carousel function
    const handle_scroll = async (event) => {
        const my_scroll = event.srcElement.scrollingElement.scrollTop;
        if((my_scroll > 1000) && (my_scroll < 1200)){
            const auto_interval = setTimeout(start_carousel, 300)
        }
        if(my_scroll > 1500){
            clearInterval(client_circle);
        }
    }

    return(
        <section className="main_wrap">
            <article className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12 text_wrap">
                        <span>몇 번의 클릭만으로 웹 데이터를<br />빠르고, 정확하게 수집.</span>
                        <div className="intro_text">
                            <pre>
                            데이터 크롤링 빠르게 도와드리겠습니다. <br />
                            데이터 수집부터 대시보드 세팅까지 한 번에!
                            </pre>
                        </div>
                        <img className="img_btn" src={estimate_btn} alt="버튼 이미지" onClick={()=>{ open_tok() }} />
                    </div>
                    <div className="col-lg-12 align-self-end image_wrap">
                        <img src={netar_image} alt="netar image" 
                            data-aos="fade-down" delay="2000"
                            data-aos-anchor=".img_btn"
                            data-aos-offset="100"
                            data-aos-duration="1000"
                            data-aos-easing="linear"
                        />
                        <img src={background_img} alt="background image" className="temp_ani" 
                            data-aos="fade-up"
                            data-aos-anchor=".img_btn"
                            data-aos-duration="500"
                            data-aos-easing="linear"
                        />
                        <img src={netar_image2} alt="netar image" 
                            data-aos="fade-down" delay="3000"
                            data-aos-anchor=".img_btn"
                            data-aos-offset="100"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                        />
                    </div>    
                </div> 
                <div className="company_wrap">
                    <div className="row">
                        <div className="col-lg-12 company_intro">고객사 소개</div>
                        <div className="col logo_wrap">
                            <div className="company_line" style={{ marginLeft : `${margin_value}px`}}>
                                <img src={cj_logo} alt="로고" />
                                <img src={kolon_logo} alt="로고" />
                                <img src={cottrell_logo} alt="로고" />
                                <img src={hyorim_logo} alt="로고" />
                                <img src={llooggoo_logo} alt="로고" />
                                <img src={kici_log} alt="로고" />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default MainPage;