import React from "react";
import first_service from '../../style/image/service/first.png';
import second_service from '../../style/image/service/second.png';
import third_service from '../../style/image/service/third.png';
import forth_service from '../../style/image/service/forth.png';

const Service = () => {

    return(
        <div className="service_wrap">
            <div className="container">
                <div className="row">
                    <h3 className="col-lg-12 service_title">기업 전용 서비스는 다음과 같은 방식으로 진행됩니다.</h3>
                </div>
                <div className="service_img_wrap">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 first_box">
                            <img src={first_service} alt="first service image" 
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-anchor=".service_img_wrap"
                            />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <img src={second_service} alt="second service image" 
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-delay="300"
                                data-aos-anchor=".service_img_wrap"
                            />
                        </div>
                        <div className="col-md-12 col-lg-6 third_box">
                            <img src={third_service} alt="third service image" 
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-delay="600"
                                data-aos-anchor=".service_img_wrap"
                            />
                        </div>
                        <div className="col-md-12 col-lg-6 forth_box">
                            <img src={forth_service} alt="forth service image" 
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-delay="900"
                                data-aos-anchor=".service_img_wrap"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;