import React from "react";
import cloud_server from "../../style/image/netar/cloud_server.png";
import crawling_picker from "../../style/image/netar/crawling_picker.png";
import data_management from "../../style/image/netar/data_management.png";
import open_API from "../../style/image/netar/open_API.png";
import proxcy_server from "../../style/image/netar/proxcy_server.png";
const Netar = () => {
    return(
        <>
            <div className="netar_wrap">
                <div className="container">
                    <div className="row">
                        <h3 className="col-lg-12 netar_title">Netar 서비스의 다양한 기능을 만나보세요.</h3>
                    </div>
                    <div className="netar_img_wrap">
                        <div className="row netar_img_box">
                            <div className="col-xs-12 col-md-6 col-lg-4 first_netar">
                                <img src={crawling_picker} alt="second service image" />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4 second_netar">
                                <img src={proxcy_server} alt="forth service image" />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4 third_netar">
                                <img src={data_management} alt="third service image" />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4 forth_netar">
                                <img src={cloud_server} alt="first service image" />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4 fifth_netar">
                                <img src={open_API} alt="forth service image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Netar;