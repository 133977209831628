import React, { useEffect, useState } from "react";
import first from '../../../style/image/netar/01_first.png';
import second from '../../../style/image/netar/02_second.png';
import third from '../../../style/image/netar/03_third.png';
import browser1 from '../../../style/image/netar/browser1.png';
import browser2 from '../../../style/image/netar/browser2.png';
import browser3 from '../../../style/image/netar/browser3.png';
import AOS from 'aos';
import "aos/dist/aos.css";

const MobileNetar = () => {

    const [margin_value, set_margin_value] = useState(410);
    var client_circle;

    // aos animation reset and refresh
    useEffect(()=>{
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(()=>{
        window.addEventListener('scroll', handle_scroll);

        return ()=>{
            window.removeEventListener('scroll', handle_scroll);
        }
    }, []);

    // margin_value reset useEffect
    useEffect(()=>{
        if(margin_value < -300){
            set_margin_value(410);
        }
    }, [margin_value])

    const start_carousel = () => {
        client_circle = setInterval(()=>{ set_margin_value(margin_value => margin_value - 1) }, 100)
        window.removeEventListener('scroll', handle_scroll);
    }

    // auto carousel function
    const handle_scroll = async (event) => {
        const my_scroll = event.srcElement.scrollingElement.scrollTop;
        if((my_scroll > 3700) && (my_scroll < 4200)){
            const auto_interval = setTimeout(start_carousel, 300)
        }
        if(my_scroll > 4300){
            clearInterval(client_circle);
        }
    }

    return(
        <>
            <div className="netar_client_wrap">
                <div className="container">
                    <div className="row">
                        <h3 className="col-lg-12 client_title">기업전용 서비스 이용 고객사 사례</h3>
                    </div>
                    <div className="client_img_wrap">
                        <div className="row">
                            <div className="col-lg-6 service_first_box">
                                <img src={first} alt="first client image" 
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="linear"
                                    data-aos-delay="400"
                                />
                            </div>
                            <div className="col-lg-6 service_second_box">
                                <img src={second} alt="second client image" 
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="linear"
                                    data-aos-delay="700"
                                />
                            </div>
                            <div className="col-lg-12 service_third_box">
                                <img src={third} alt="phone image" 
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="linear"
                                    data-aos-delay="1000"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="browser_wrap">
                        <div className="row">
                            <div className="col slide_wrap">
                                <div className="browser_line" style={{ marginLeft : `${margin_value}px`}}>
                                    <img src={browser1} alt="로고" 
                                        data-aos="fade-up"
                                        data-aos-duration="500"
                                        data-aos-easing="linear"
                                    />
                                    <img src={browser2} alt="로고"
                                        data-aos="fade-up"
                                        data-aos-duration="500"
                                        data-aos-easing="linear"
                                    />
                                    <img src={browser3} alt="로고"
                                        data-aos="fade-up"
                                        data-aos-duration="500"
                                        data-aos-easing="linear"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileNetar;