import React from "react";
import ContactUs from "../component/contact/ContactUs";
import GoogleMapComponent from "../component/contact/GoogleMapComponent";
import { useTop } from "../customHooks/useScrollHooks";

const ContactRoute = () => {

    const scroll_top = useTop();

    return(
        <>
            <GoogleMapComponent />
            <ContactUs />
        </>
    )
}

export default ContactRoute;