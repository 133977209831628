import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import logo_img from '../../style/image/grey_logo.png';
import blog from '../../style/image/footer/blog_circle.png';
import notion from '../../style/image/footer/notion-logo-1.png';
import { AiOutlineMenu } from 'react-icons/ai';
import { use_move_page } from '../../customHooks/useClickHooks.js';
import { useScroll } from '../../customHooks/useScrollHooks';
import Swal from 'sweetalert2';
import { useState } from "react";

const Header = () => {

    const [menu_flag, set_menu_flag] = useState(false);

    const { move_page } = use_move_page();
    //const header_mode = useScroll();

    const open_menu = () => {
        set_menu_flag(!menu_flag);
    }

    return (
        <header className="view_header">
            <div className="container header_container">
                <div className="col logo_wrap">
                    <Link to="/">
                        <img src={logo_img} alt="wise solution 로고" />
                    </Link>
                </div>
                <div className="header_right_wrap">
                    <div className="text_wrap">
                        <span>
                            <Link to="/">데이터 솔루션</Link>
                        </span>
                        {/*<span  onClick={()=>{
                            Swal.fire({
                                title : '좌송합니다.',        
                                text : '기능을 준비중입니다.',  
                                icon : 'info'
                            })
                        >올인원 워크스페이스</span>
                        }}*/}
                        <span>
                            <Link to="/contact">Contact us</Link>
                        </span>
                        {/* <div className="contour_line"></div> */}
                        <div className="icon_wrap">
                            {/* <img src={notion} alt="notion icon"
                                onClick={() => { move_page('https://wisenexus.oopy.io/') }}
                            />
                            <img src={blog} alt="blog icon"
                                onClick={() => { move_page('https://blog.naver.com/page1corp') }}
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="menu_wrap" onClick={() => {
                    open_menu()
                }}>
                    <AiOutlineMenu />
                </div>
            </div>
            <div className="mobile_menu" style={menu_flag ? { height: "344.391px" } : { height: "0" }}>
                <ul>
                    <li>
                        <Link to="/" onClick={() => { set_menu_flag(false) }}>데이터 솔루션</Link>
                    </li>
                    <li onClick={() => {
                        move_page('https://www.pageone.name/menu/7c1bdc0e309d4c4abc3d5d035cc7b739')
                        set_menu_flag(false);
                    }}>올인원 워크스페이스
                    </li>
                    <li>
                        <Link to="/contact" onClick={() => { set_menu_flag(false) }}>Contact Us</Link>
                    </li>
                </ul>
                <ul>
                    <li onClick={() => {
                        move_page('https://wisenexus.oopy.io/')
                        set_menu_flag(false);
                    }}>
                        <img src={notion} alt="notion icon" />
                        와이즈넥서스 Notion
                    </li>
                    <li onClick={() => {
                        move_page('https://blog.naver.com/page1corp')
                        set_menu_flag(false);
                    }}>
                        <img src={blog} alt="blog icon" />
                        와이즈넥서스 Blog
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default Header;