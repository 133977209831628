import React from "react";
import kolon from '../../style/image/client/01_case.png';
import cj from '../../style/image/client/02_case.png';
import third from '../../style/image/client/03_case.png';
import phone from '../../style/image/client/phone.png';
import logo from '../../style/image/netar/logo.png';
import arrow from '../../style/image/netar/arrow.png';
import Swal from "sweetalert2";
import { use_move_page } from "../../customHooks/useClickHooks";

const Netar = () => {

    const { move_page } = use_move_page();

    return(
        <>
            <div className="netar_client_wrap">
                <div className="container">
                    <div className="row">
                        <h3 className="col-lg-12 client_title">기업 전용 서비스 이용 고객사 사례</h3>
                        <span>
                            서비스 특성상 고객사 이름을 나열 할 수 없는 점 이해 부탁드리며 <br />
                            그 외 수많은 고객사 경험으로 보다 빠르고 정확한 합리적인 서비스를 위해 연구하고 있습니다.
                        </span>
                    </div>
                    <div className="client_img_wrap">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 first_box">
                                <img src={kolon} alt="first client image" 
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="linear"
                                    data-aos-delay="700"
                                    data-aos-anchor=".client_img_wrap"
                                />
                            </div>
                            <div className="col-md-6 col-lg-6 second_box">
                                <img src={cj} alt="second client image" 
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="linear"
                                    data-aos-delay="1200"
                                    data-aos-anchor=".client_img_wrap"
                                />
                            </div>
                            <div className="col-md-12 col-lg-12 third_box">
                                <img src={phone} alt="phone image" 
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="linear"
                                    data-aos-delay="1700"
                                    data-aos-anchor=".client_img_wrap"
                                />
                                <img src={third} alt="third client image" 
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-easing="linear"
                                    data-aos-delay="2200"
                                    data-aos-anchor=".client_img_wrap"
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="wait_wrap">
                        <div className="row">
                            <div className="proposal_wrap">
                                <h4>잠시만요!</h4>
                                <p>
                                    <span>데이터 수집 &middot; 시각화에</span><span> 자동화</span>를 더하고 싶진 않나요?
                                </p>
                                <div className="border_div"></div>
                                <div className="pageone_wrap" onClick={()=>{ move_page('https://www.pageone.name/menu/7c1bdc0e309d4c4abc3d5d035cc7b739') }}>
                                    <img src={logo} alt="페이지원 로고" />
                                    <img src={arrow} alt="화살 아이콘" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Netar;