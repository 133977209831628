import React, { useState } from "react";
import GoogleMap from "google-map-react";
import Marker from "./Marker";
import { useDimmed } from '../../customHooks/useClickHooks';
import * as global_var from '../../global';

const GoogleMapComponent = () => {

    const { clear_dimmed, dimmed_mode } = useDimmed();

    return(
        <>
            <div className={ dimmed_mode ? "padding_map_wrap" : "map_wrap"} 
                onClick={()=>{ clear_dimmed() }}
            >
                <GoogleMap
                    bootstrapURLKeys={{ key : global_var.GOOGLE_MAP }}
                    defaultZoom={15}
                    defaultCenter={{ lat : 37.415233, lng : 126.9774389 }}
                    draggable={false}
                >
                    <Marker lat={37.415233} lng={126.9774389}/>
                </GoogleMap>
            </div>
        </>
    )
}

export default GoogleMapComponent;