import React, { useEffect, useState } from "react";


// 반응형을 위한 사용자 디바이스 width 측정 메소드
export const DeviceWidth = () => {
    const [mobile_devide, set_mobile_device] = useState(null);

    const handle_resize = () => {
        console.log(window.innerWidth);

        if(window.innerWidth < 767){
            set_mobile_device(true);
        } else {
            set_mobile_device(false);
        }
    }

    useEffect(()=>{

        if(window.innerWidth < 767){
            set_mobile_device(true);
        } else {
            set_mobile_device(false);
        }

        window.addEventListener('resize', handle_resize);

        return()=>{
            window.removeEventListener('resize', handle_resize);
        }
    }, []);

    return mobile_devide;
}