import React, { useState, useEffect, useCallback} from "react";
import { useLocation } from "react-router-dom";
import useThrottle from './useThrottle';
import useDebounce from './useDebounce';

export const useScroll = () => {
    const [prev_y, set_prev_y] = useState(0);
    const [header_show, set_header_show] = useState(true);


    const handleScroll = useCallback((e) => {
      const user_scroll = window.scrollY;
      console.log(user_scroll);
      if(user_scroll !== prev_y){
        console.log('움직이는중')
        set_header_show(false);
      }
      set_prev_y(user_scroll);
    }, [])


    const stop_scroll = useCallback((e)=>{
      const user_scroll = window.scrollY;
        if(e.srcElement.scrollingElement.scrollTop === 0 || user_scroll !== prev_y){
          set_header_show(true);
        } else {
          set_header_show(false);
        }
    }, []);

    const throttle_scroll = useThrottle(handleScroll, 300);
    const debounce_scroll = useDebounce(stop_scroll, 500);
    const scroll_detect_handler = useCallback((...e)=>{
        throttle_scroll(...e);
        debounce_scroll(...e);
    }, [])

    useEffect(() => {
      window.addEventListener('scroll', scroll_detect_handler )
      return () => {
        window.removeEventListener('scroll', scroll_detect_handler )
      }
    }, [prev_y]);

    return header_show ;
}

export const useTop = () => {
    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [pathname]);

    return null;
}