import React, { useEffect } from "react";
import first_service from '../../../style/image/service/1.png';
import second_service from '../../../style/image/service/2.png';
import third_service from '../../../style/image/service/3.png';
import forth_service from '../../../style/image/service/4.png';
import data_crawling from '../../../style/image/service/02_dc.png';
import data_label from '../../../style/image/service/04_label.png';
import excel_btn from '../../../style/image/service/06_ex.png';
import level_set from '../../../style/image/service/08_admin.png';
import AOS from 'aos';

const MobileService = () => {

    // aos animation reset and refresh
    useEffect(()=>{
        AOS.init();
        AOS.refresh();
    }, []);

    return(
        <div className="service_wrap">
            <div className="container">
                <div className="row">
                    <h3 className="col-lg-12 service_title">기업전용 서비스는<br />다음과 같은 기능을 수행합니다.</h3>
                </div>
                <div className="service_img_wrap">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 first_box"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="linear"
                            data-aos-delay="400"
                            data-aos-anchor=".first_box"
                        >
                            <img src={first_service} alt="first service image" />
                            <p>전달 받은 대상 URL의 데이터를 수집합니다.</p>
                            <img src={data_crawling} alt="first service image" />
                        </div>
                        <div className="col-md-12 col-lg-6 second_box" 
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="linear"
                            data-aos-anchor=".first_box"
                            data-aos-delay="700"
                        >
                            <img src={second_service} alt="second service image" />
                            <p>판단 기준에 따라 데이터 분류작업을<br />진행합니다.</p>
                            <img src={data_label} alt="second service image" />
                        </div>
                        <div className="col-md-12 col-lg-6 third_box" 
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="linear"
                            data-aos-anchor=".second_box"
                            data-aos-delay="1000"
                        >
                            <img src={third_service} alt="third service image" />
                            <p>수집, 분류된 데이터를 엑셀로 다운받습니다.</p>
                            <img src={excel_btn} alt="third service image" />
                        </div>
                        <div className="col-md-12 col-lg-6 forth_box" 
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="linear"
                            data-aos-anchor=".third_box"
                            data-aos-delay="1300"
                        >
                            <img src={forth_service} alt="forth service image" />
                            <p>데이터 수집 전용 워크스페이스를 통해<br />사용자 권한 별 데이터 통계를 확인합니다.</p>
                            <img src={level_set} alt="forth service image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileService;