import React from "react";
import netar_video from '../../style/video/netar_intro.mp4';
import back_img from '../../style/image/back_img.png';
import hello_text from '../../style/image/hello.png';
import { useScroll } from "../../customHooks/useScrollHooks";

const Video = () => {

    return(
        <>
            <div className="temp_bg"></div>
            <div className="container">
                <div className="video_wrap">
                    <div className="video_frame">
                        <video src={netar_video} autoPlay="autoplay" muted loop playsInline preload="auto" >
                            <source src={netar_video} type="video/webm"/>
                        </video>
                    </div>
                    <img src={back_img} alt="백그라운드 이미지"/>
                    <img src={hello_text} alt="Hello 텍스트" />
                    <div className="video_text_wrap">
                        <p>데이터 수집 전문</p>
                        <p>와이즈 넥서스입니다.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Video;