import React, { useState } from "react";
import ChannelService from "../component/common/ChannelService";

export const use_move_page = () => {

    // 새 탭 열기 메소드
    const move_page = (param) =>{
        window.open(`${param}`);
    }

    return { move_page }
}

export const use_channel_tok = () => {

    // 채널톡 오픈 메소드
    const open_tok = () => {
        ChannelService.showMessenger();
    }

    return { open_tok }
}

export const useDimmed = () => {
    const [dimmed_mode, set_dimmed_mode] = useState(true)

    // 딤 비활성화 메소드
    const clear_dimmed = () => {
        set_dimmed_mode(false);
    }

    return { clear_dimmed, dimmed_mode }
}