import Header from "./component/common/Header";
import './style/scss/index.scss';
import './style/scss/mobile.scss';
import './style/scss/tablet.scss';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./component/common/Footer";
import MainRoute from "./routes/MainRoute";
import ContactRoute from "./routes/ContactRoute";
import ChannelService from './component/common/ChannelService';
import * as global_var from './global';

ChannelService.boot({
  // "pluginKey": global_var.CHANNEL_TOK, //please fill with your plugin key
});

function App() {

  return (
    <BrowserRouter basename="/home">
      <div className="App">
        <Header />
          <Routes>
            <Route path="/" element={ <MainRoute /> } />
            <Route path="/contact" element={ <ContactRoute/> } />
          </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
