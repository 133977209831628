import React, { useState } from "react";
import Video from "../component/main/Video";
import MainPage from "../component/main/MainPage";
import MobileMainPage from "../component/main/mobile/MobileMainPage";
import MobileNetarFunction from "../component/main/mobile/MobileNetarFunction";
import MobileService from '../component/main/mobile/MobileService';
import MobileNetar from '../component/main/mobile/MobileNetar';
import Service from "../component/main/Service";
import Netar from "../component/main/Netar";
import NetarFunction from '../component/main/NetarFunction';
import { useTop, useScroll } from "../customHooks/useScrollHooks";
import { DeviceWidth } from "../customHooks/useDevice";

const MainRoute = () => {

    const scroll_top = useTop();

    const user_device = DeviceWidth();

    return(
        <>
          { user_device ?
            <>
              <MobileMainPage />
              <MobileNetarFunction />
              <MobileService />
              <MobileNetar />
            </>
            
            :

            <>
              <Video/>
              <MainPage />
              <NetarFunction />
              <Service />
              <Netar />
            </> 
          }
        </>
    )
}

export default MainRoute;