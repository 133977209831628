import React from "react";
import blog from '../../style/image/footer/blog_circle.png';
import notion from '../../style/image/footer/notion-logo-1.png';
import wise_solution from '../../style/image/footer/wise_solution_logo.png';
import Swal from "sweetalert2";
import { use_move_page } from "../../customHooks/useClickHooks";
import { Link } from "react-router-dom";


const Footer = () => {

    const { move_page } = use_move_page();

    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <img src={wise_solution} alt="wise_solution logo" />
                    </div>
                    <div className="col-lg-12 copyright_wrap">
                        {/* <p onClick={()=>{ move_page('https://www.wisenexus.co.kr/main/home3/privacy.html') }}>개인정보취급 방침</p> */}
                        <p>
                            데이터 수집부터 시각화까지<br />
                            올 인원 워크스페이스 제작 와이즈넥서스
                        </p>
                        <p>
                            Copyright @ 2024 wisenexus. All right reserved.
                        </p>
                        <p>
                            (주)와이즈넥서스의 모든 이미지 및 컨텐츠의 저작권은 (주)와이즈넥서스에 있으며 무단복제나 도용은 저작권법에 의해 금지되어 있습니다<br />
                            이를 위반할 시에는 법적인 처벌을 받을 수 있습니다.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;