import React, { useEffect } from "react";
import cloud_server from "../../../style/image/netar/02_cs.png";
import crawling_picker from "../../../style/image/netar/01_cp_new_size_up.png";
import data_management from "../../../style/image/netar/05_dm.png";
import open_API from "../../../style/image/netar/03_oa.png";
import proxcy_server from "../../../style/image/netar/04_ps.png";
import AOS from 'aos';
import "aos/dist/aos.css";

const MobileNetarFunction = () => {
    
    // aos animation reset and refresh
    useEffect(()=>{
        AOS.init();
        AOS.refresh();
    }, []);

    return(
        <>
            <div className="netar_wrap">
                <div className="container">
                    <div className="row">
                        <h3 className="col-lg-12 netar_title">Netar 서비스의<br />다양한 기능을 만나보세요.</h3>
                    </div>
                    <div className="netar_img_wrap">
                        <div className="row">
                            <div className="col-md-12 col-lg-4 first_netar"
                                data-aos="fade-up-left"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                            >
                                <img src={crawling_picker} alt="second service image" />
                            </div>
                            <div className="col-md-12 col-lg-4 second_netar"
                                data-aos="fade-up-left"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-delay="300"
                            >
                                <img src={proxcy_server} alt="forth service image" />
                            </div>
                            <div className="col-md-12 col-lg-4 third_netar"
                                data-aos="fade-up-right"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-delay="600"
                            >
                                <img src={data_management} alt="third service image" />
                            </div>
                            <div className="col-md-12 col-lg-4 forth_netar"
                                data-aos="fade-up-left"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-delay="900"
                            >
                                <img src={cloud_server} alt="first service image" />
                            </div>
                            <div className="col-md-12 col-lg-4 fifth_netar"
                                data-aos="fade-up-right"
                                data-aos-duration="500"
                                data-aos-easing="linear"
                                data-aos-delay="1200"
                            >
                                <img src={open_API} alt="forth service image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileNetarFunction;